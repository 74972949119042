export const staticRouter = [
  {
    path: "/",
    redirect: "/spread/display",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../../views/login/index.vue"),
  },

  {
    path: "/layout",
    name: "layout",
    component: () => import("@/layouts/index.vue"),
    redirect: "/spread/display",
    children: [],
  },
];
