export default {
    namespaced: true,
    state: {
        count: 1,
    },
    mutations: {
        increment(state, n = 1) {
            state.count += n;
        },
        decrement(state, m) {
            state.count -= m;
        },
    },
    getters: {},
    actions: {},
}