import { routes } from "@/router/modules/route";
import router from "@/router";
import store from "@/store";
import { resetRouter } from "@/router";
import { getMenu } from "@/api/modules/login";
export function initDynamicRouter(dynamicRouter) {
  const routers = [];
  const _recurseGetRoute = (menus) => {
    for (const menu of menus) {
      const route = routes.find((file) => {
        return file.path == menu.url;
      });
      if (route) routers.push(route);
      if (menu.children && menu.children !== 0) {
        _recurseGetRoute(menu.children);
      }
    }
  };
  _recurseGetRoute(dynamicRouter);
  return routers;
}

// 添加动态路由
export async function addDynamicRouter() {
  resetRouter();
  // 不是登录页面，且没有动态添加过路由 刷新路由丢失
  const { data: menuData } = await getMenu();
  store.dispatch("menu/saveMenu", menuData).then(() => {
    const dynamicRouter = store.state.menu.dynamicRouter;
    // 添加路由
    dynamicRouter.forEach((v) => {
      router.addRoute("layout", v);
    });
    // 在这动态添加最后的通配路由，确保先有动态路由、再有通配路由，解决动态路由刷新会跳转到404问题
    let lastRou = { path: "/:pathMatch(.*)*", component: () => import("@/components/ErrorMessage/404.vue") };
    router.addRoute("layout", lastRou);
  });
}
