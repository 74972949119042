export default {
    namespaced: true,
    state: {
        firstName: 'Tom',
        lastName: 'smith',
    },
    mutations: {},
    getters: {
        fullName({ firstName, lastName }) {
            return firstName + ' ' + lastName;
        }
    },
    actions: {},
}