import { login } from "@/api/modules/login";
import _this from "@/main.js";
export default {
  namespaced: true,
  state: {
    info: {},
  },
  mutations: {
    saveToken(state, data) {
      state.token = data;
    },
    saveInfo(state, data) {
      state.info = data;
    },
  },
  actions: {
    async login(context, payload) {
      const { data, message, status } = await login({ ...payload });
      if (status == "1") {
        // 存储token到localStorage
        localStorage.setItem("token", JSON.stringify(data.token));
        context.commit("saveInfo", data.userData);
      } else {
        _this.$message.error(message);
        return false;
      }
    },
    async layout(context) {
      // 清空用户信息
      localStorage.removeItem("token");
      context.commit("saveInfo", {});
    },
  },
};
