// 本地路由信息表
export const routes = [
  {
    path: "/spread/display",
    name: "display",
    component: () => import("../../views/spread/display/index.vue"),
    meta: {
      title: "展示推广",
      keepAlive: true,
    },
  },
  {
    path: "/spread/client",
    name: "client",
    component: () => import("../../views/spread/client/index.vue"),
    meta: {
      title: "外部客户",
      keepAlive: true,
    },
  },
  {
    path: "/spread/dosingReport",
    name: "dosingReport",
    component: () => import("../../views/spread/dosingReport/index.vue"),
    meta: {
      title: "加粉报告",
    },
  },
  {
    path: "/spread/clue",
    name: "clue",
    component: () => import("../../views/spread/clue/index.vue"),
    meta: {
      title: "转化线索",
      keepAlive: true,
    },
  },
  {
    path: "/spread/page",
    component: () => import("@/views/spread/page/manage/index.vue"),
    meta: {
      title: "模块建站",
    },
  },
  {
    path: "/spread/page/create/:id?",
    component: () => import("@/views/spread/page/create/index.vue"),
    meta: {
      title: "模块建站-创建",
    },
  },
  {
    path: "/spread/site",
    component: () => import("@/views/spread/site/manage/index.vue"),
    meta: {
      title: "站点管理",
    },
  },
  {
    path: "/spread/site/create/:id?",
    component: () => import("@/views/spread/site/create/index.vue"),
    meta: {
      title: "站点管理-创建",
    },
  },
  {
    path: "/spread/rule",
    component: () => import("@/views/spread/rule/manage/index.vue"),
    meta: {
      title: "分配规则",
    },
  },
  {
    path: "/spread/rule/create/:id?",
    component: () => import("@/views/spread/rule/create/index.vue"),
    meta: {
      title: "分配规则-创建",
    },
  },
  {
    path: "/spread/config",
    component: () => import("@/views/spread/config/index.vue"),
    meta: {
      title: "转化追踪",
    },
  },
  {
    path: "/spread/efficient",
    component: () => import("@/views/spread/efficient/index.vue"),
    meta: {
      title: "有效率",
    },
  },
  {
    path: "/spread/counselor",
    component: () => import("@/views/spread/counselor/index.vue"),
    meta: {
      title: "咨询师分配情况",
    },
  },
  {
    path: "/manage/module",
    component: () => import("@/views/manage/module/index.vue"),
    meta: {
      title: "模块管理",
    },
  },
  {
    path: "/manage/company",
    component: () => import("@/views/manage/company/index.vue"),
    meta: {
      title: "企业管理",
    },
  },
  {
    path: "/manage/role",
    component: () => import("@/views/manage/role/index.vue"),
    meta: {
      title: "角色管理",
    },
  },
  {
    path: "/manage/person",
    component: () => import("@/views/manage/person/index.vue"),
    meta: {
      title: "人员管理",
    },
  },
  {
    path: "/manage/customer",
    component: () => import("@/views/manage/customer/index.vue"),
    meta: {
      title: "客服管理",
    },
  },
  {
    path: "/statement/report",
    component: () => import("@/views/statement/report/index.vue"),
    meta: {
      title: "巨量数据报表",
    },
  },
  {
    path: "/statement/channelReport",
    component: () => import("@/views/statement/channelReport/index.vue"),
    meta: {
      title: "全渠道数据报表",
    },
  },
  {
    path: "/unanswered/bell",
    component: () => import("@/views/unanswered/bell/index.vue"),
    meta: {
      title: "响铃时长分析",
    },
  },
  {
    path: "/unanswered/interval",
    component: () => import("@/views/unanswered/interval/index.vue"),
    meta: {
      title: "间隔拨打时长分析",
    },
  },
  {
    path: "/unanswered/withinDayCalls",
    component: () => import("@/views/unanswered/withinDayCalls/index.vue"),
    meta: {
      title: "24小时拨打次数分析",
    },
  },
];
