import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// 导入子模块
import counter from "./modules/counter";
import name from "./modules/name";
import menu from "./modules/menu";
import user from "./modules/user";

// vuex持久化存储的原理
function save(store) {
  // 当 store 初始化后调用
  // 1、获取localstorage存储的state
  let { vuex } = localStorage;
  // 2、用以前备份的state替换初始化的state
  store.replaceState({ ...vuex });

  // 每次 mutation 之后调用
  store.subscribe((mutation, state) => {
    localStorage.vuex = JSON.stringify(state);
  });
}

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    counter,
    name,
    menu,
    user,
  },
  plugins: [createPersistedState()],
  // plugins: [save],
});
