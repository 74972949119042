import { initDynamicRouter } from "@/router/modules/dynamicRouter";
export default {
  namespaced: true,
  state: {
    isCollapse: false,
    dynamicRouter: [],
    menuList: [],
  },
  getters: {
    GET_MENULIST: (state) => {
      return state.dynamicRouter;
    },
  },
  mutations: {
    toggleMenu(state) {
      state.isCollapse = !state.isCollapse;
    },
    saveMenuList(state, data) {
      state.menuList = data;
      const menuList = initDynamicRouter(data);
      state.dynamicRouter = menuList;
    },
  },
  getters: {},
  actions: {
    saveMenu(context, payload) {
      // 保存用户路由菜单
      context.commit("saveMenuList", payload);
    },
  },
};
