import _this from "@/main";
import router from "@/router";

/**
 * @description: 校验网络请求状态码
 * @param {Number} status
 * @return void
 */
export const checkStatus = (status) => {
  switch (status) {
    case 400:
      _this.$message.error("请求失败！请您稍后重试");
      break;
    case 401:
      _this.$message.error("登录失效！请您重新登录");
      break;
    case 403:
      _this.$message.error("Token过期！请您重新登录");
      // 清除token
      localStorage.removeItem("token");
      let { fullPath } = router.history.current;
      router.replace({ path: "/login", query: { redirect: fullPath } });
      // window.location.href = "/";
      break;
    case 404:
      _this.$message.error("你所访问的资源不存在！");
      break;
    case 405:
      _this.$message.error("请求方式错误！请您稍后重试");
      break;
    case 408:
      _this.$message.error("请求超时！请您稍后重试");
      break;
    case 500:
      _this.$message.error("服务异常,请刷新页面！");
      break;
    case 502:
      _this.$message.error("网关错误！");
      break;
    case 503:
      _this.$message.error("服务不可用！");
      break;
    case 504:
      _this.$message.error("网关超时！");
      break;
    default:
      _this.$message.error("请求失败,请刷新页面！！");
  }
};
